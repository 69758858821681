.filter-voertuigtypes-container {
  width: 100%;
  height: auto;
  
  background-color: transparent;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0px;
  color: #000000;
}

.filter-voertuigtypes-title {
  text-align: left;
  opacity: 1;
  background-color: transparent;
  width: auto;
  height: 1.4em;
}

.filter-voertuigtypes-title-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 5px;
  padding-right: 15px;
}

.filter-voertuigtypes-reset {
  color: #B2B2B2;
}

.filter-voertuigtypes-box-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: auto;
  padding: 8px 5px;

  flex-grow: 0 1;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  opacity: 1;
}

.filter-voertuigtypes-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  border-right: 1px solid black;
  cursor: pointer;
  color: #343E47;
}

.filter-voertuigtypes-item-excluded {
  color: #AFAFAF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 60px;
  border-right: 1px solid black;
  user-select: none;
  cursor: pointer;
}

.filter-voertuigtypes-item:last-child,

.filter-voertuigtypes-item-excluded:last-child {
  border-right: 0;
}

.filter-voertuigtypes-itemlabel {
  margin-top: 5px;
  text-align: center;
  font: normal normal normal 10px/12px Inter;
  letter-spacing: 0px;
  opacity: 1;
  color: #343E47;
}

.filter-voertuigtypes-item-excluded .filter-voertuigtypes-itemlabel {
  color: #AFAFAF;
}

.filter-voertuigtypes-icon {
  width: 40px;
  height: 28px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  color: #AFAFAF;
  background-size: 26px;
}

.filter-voertuigtypes-item-excluded .filter-voertuigtypes-icon {
  filter: none;
  color: #343E47;
}

.filter-voertuigtypes-icon-bicycle {
  background-image: url('../../../images/filterbar/fiets-active.svg');
  background-size: 28px;
}

.filter-voertuigtypes-item-excluded .filter-voertuigtypes-icon-bicycle {
  background-image: url('../../../images/filterbar/fiets.svg');
}

.filter-voertuigtypes-icon-cargo-bicycle {
  background-image: url('../../../images/filterbar/bakfiets-active.svg');
  background-size: 34px;
}

.filter-voertuigtypes-item-excluded .filter-voertuigtypes-icon-cargo-bicycle {
  background-image: url('../../../images/filterbar/bakfiets.svg');
}

.filter-voertuigtypes-icon-scooter {
  background-image: url('../../../images/filterbar/scooter-active.svg');
  background-size: 24px;
}

.filter-voertuigtypes-item-excluded .filter-voertuigtypes-icon-scooter {
  background-image: url('../../../images/filterbar/scooter.svg');
}

.filter-voertuigtypes-icon-car {
  background-image: url('../../../images/filterbar/auto-active.svg');
  background-size: 30px;
}

.filter-voertuigtypes-item-excluded .filter-voertuigtypes-icon-car {
  background-image: url('../../../images/filterbar/auto.svg');
}

.filter-voertuigtypes-icon-other {
  background-image: url('../../../images/filterbar/172591_dinghy_icon-active.svg');
}

.filter-voertuigtypes-item-excluded .filter-voertuigtypes-icon-other {
  background-image: url('../../../images/filterbar/172591_dinghy_icon.svg');
}