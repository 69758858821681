.filter-bar-inner {
  /*width: 290px;*/
  max-width: 100%;
}

@media (min-width: 640px) {
  .filter-bar-inner {
    max-width: 290px;
  }
}

/* Generic items ------------------------------------------------------------*/
.filter-form-values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filter-form-search-container {
  height: 3em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-form-search-container-2 {
  display: flex;
  flex-direction: row;
  border-radius: 0.25em;
  margin-right: 40px;
}

.filter-form-search {
  padding: 5px 10px;
  border: 1px solid #CCCCCC;
}

.form-item {
  display: inline-block;
  padding-top: 0.25rem; /* 4px */
  padding-bottom: 0.25rem; /* 4px */
  padding-left: 0.5rem; /* 8px */
  padding-right: 0.5rem; /* 8px */
  margin-right: 0.5rem; /* 8px */
  margin-bottom: 0.5rem; /* 8px */
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
  cursor: pointer;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 3px;
  color: #343E47;
  opacity: 1;
  text-overflow:clip;
  white-space: nowrap;
  overflow:hidden;
}

.form-item-selected {
  color: white;
  font-weight:bolder;
  background-color: #15AEEF;
}

.filter-form-selectie {
  display: flex;
  flex-direction: column;
  /*overflow: auto;*/
}