.SelectLayer {
  user-select: none;
  display: flex;
}
.SelectLayer-inner {
  /*min-height: 100px;*/
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  display: flex;
}
.SelectLayer .layer {
  width: 80px;
  height: 80px;
  border: 1px solid #343E47;
  border-radius: 10px;
  opacity: 1;
  background: #fff center center no-repeat;
  background-size: cover;
  cursor: pointer;
  
  position: relative;
  display: inline-block;
  margin-top: 0.75rem; /* 12px */
  margin-bottom: 0.75rem; /* 12px */
  margin-left: 0.25rem; /* 4px */
  margin-right: 0.25rem; /* 4px */
}
.SelectLayer .layer[data-type="vehicles"] {
  background-image: url('../../images/SelectLayer/layer-vehicles.png')
}
.SelectLayer .layer[data-type="heat-map"] {
  background-image: url('../../images/SelectLayer/layer-heatmap.png')
}
.SelectLayer .layer[data-type="od"] {
  background-image: url('../../images/SelectLayer/layer-od.png')
}
.SelectLayer .layer[data-type="pointers"] {
  background-image: url('../../images/SelectLayer/layer-clusters.png')
}

.SelectLayer .layer[data-type='zones'] {
  background-image: url('../../images/layer-zones.png')
}
.SelectLayer .layer[data-type='map-style-default'] {
  background-image: url('../../images/layer-map-style-default.png')
}
.SelectLayer .layer[data-type='map-style-satellite'] {
  background-image: url('../../images/layer-map-style-satellite.png')
}

.SelectLayer .layer-title {
  text-align: center;
  font: normal normal normal 12px/15px Inter;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: rgba(255, 255, 255, 0.8);

  display:block;
  position: absolute;
  bottom:0;
  left:0;
  width: 100%;
  height: 1.5rem; /* 24px */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.layer-inactive {
  opacity: 0.6;
  filter: grayscale(100%);
}
.layer-inactive .layer-title {
  font-weight: normal;
}
