.filter-markers-container {
  width: 100%;
  height: auto;
  
  background-color: transparent;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0px;
  color: #000000;
}

.filter-markers-title {
  text-align: left;
  opacity: 1;
  background-color: transparent;
  width: auto;
  height: 1.4em;
}

.filter-markers-title-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 5px;
  padding-right: 15px;
}

.filter-markers-reset {
  color: #B2B2B2;
}

.filter-markers-box-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: auto;
  padding: 10px 8px;

  flex-grow: 0 1;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  opacity: 1;
}

.filter-markers-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40px;
  user-select: none;
  cursor: pointer;
}

.filter-markers-item-excluded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40px;
  opacity: 0.6;
  filter: grayscale(100%);
  user-select: none;
  cursor: pointer;
}

.filter-markers-marker {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
}

.filter-markers-itemlabel {
  margin-top: 5px;
  text-align: center;
  font: normal normal normal 10px/12px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  min-height: 24px;/* 2 lines */
}
