/* Inspiration from http://sherubthakur.github.io/react-horizontal-timeline/ */

.EventsTimeline {
    display: block;
    background: #fff;
    width: calc(100vw - 360px - 90px);
    height: 125px;
    overflow-y: hidden;
}
.EventsTimeline .vis-timeline {
    border-radius: 1.5rem;
}
.EventsTimeline .inner {
    position: absolute;
    left: 0px;
    top: 20px;
    height: 2px;
    width: 100%;
    transform: translate3d(0px, 0px, 0px);
}
.EventsTimeline .line {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    transform-origin: left center;
    background-color: rgb(223, 223, 223);
}
.EventsTimeline .events-wrapper {
    display:flex;
    justify-content:space-around;
}
.EventsTimeline .event {
    text-align: center;
    padding-bottom: 15px;
    cursor: pointer;
}
.EventsTimeline .dot-label {
    position:relative;
    color: #383838;
}
.EventsTimeline .dot-label .event-title {
    border:solid 2px #fff;
    opacity:0;
    position: absolute;
    right:-60px;
    top:-42px;
    white-space: nowrap;
    background-color: #15AEEF;
    padding: 2px 5px;
    display: block;
    color: #fff;
    font-weight: bold;
    transition: all 0.2s;
}
.EventsTimeline .dot-label:hover .event-title,
.EventsTimeline .dot-label.is-active .event-title {
    opacity: 1;
}
.EventsTimeline .dot-label:hover .event-title {
    z-index: 1;
}
.EventsTimeline:hover .dot-label.is-active .event-title {
    transform: scale(1);
}
.EventsTimeline:hover .dot-label .event-title,
.EventsTimeline:hover .dot-label:hover.is-active .event-title {
    transform: scale(1.4);
}

.EventsTimeline .dot-label.is-active .event-title {
    background-color: #fff;
    color: #000;
}
.EventsTimeline .dot {
    position: absolute;
    bottom: 8px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
    background-color: #15AEEF;
    border: 2px solid #15AEEF;
}
.EventsTimeline .dot.is-active {
    border-color: #000;
    background-color: #fff;
}
