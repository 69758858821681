.Menu {
  opacity: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transition: width 1s;
  transition-timing-function: ease-out;
  right: 0;
  z-index: 100;
}

.Menu-inner {
  overflow-x: auto;
}

@media (min-width: 640px) {
  .Menu {
    right: 50px;/* Some space because of map action buttons */
    padding-left: 50px;
  }
  .Menu.filter-open {
    transition-timing-function: ease-out;
    width: calc(100% - 324px);
  }

  .Menu-inner {
    padding-left: 1rem; /* 16px */
    padding-right: 1rem; /* 16px */
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    margin: 1rem; /* 16px */
    margin-bottom: 0px;
    border-radius: 1.5rem; /* 24px */

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: inline-block;
    max-width: 100%;
    width: auto;
  }

  @media (max-width: 964px) {
    .filter-open .Menu-inner span {
      display: none;
    }
  }
}

.toggleactive {
  color: black;
  background-color:white;
}

.togglenotactive {
  color: white;
  background-color:transparent;
}

.text-menu {
  position: relative;
  color: #000;
  padding-left: 0.75rem; /* 12px */
  padding-right: 0.75rem; /* 12px */
  display:inline-block;
  font-size: 14px;
  line-height: 60px;
  height: 60px;
  white-space: nowrap;
}
.text-menu:hover {
  color: rgb(30 64 175);;
}

.text-menu::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 28px;
  top: 16px;
  border-right: solid 1px #000;
  position: absolute;
  right: 0;
}
.text-menu:hover,
.text-menu.is-active {
  color: #15AEEF;
}

.text-menu img {
  height: 25px;
  display: inline-block;
  margin-left: 0.25rem; /* 4px */
  margin-right: 0.25rem; /* 4px */
}
.text-menu:last-child::after {
  border-right: none;
}

.Menu-subMenu {
  bottom: 68px;
  background: white;
  right: 0;
  padding-top: 0.25rem; /* 4px */
  padding-bottom: 0.25rem; /* 4px */
}
@media (min-width: 640px) {
  .Menu-subMenu {
    bottom: 78px;
  }
}

.text-link {
  position: relative;
  color: #000;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
  display: block;

  font-size: 14px;
  border-radius: 17px;
  white-space: nowrap;
}
.text-link:hover {
  color: rgb(30 64 175);;
}
.text-link:hover,
.text-link.is-active {
  color: #15AEEF;
}


/*
.text-menu.has-icon.icon-aanbod { padding-left: 21px;background-image: url("https://www.dashboarddeelmobiliteit.nl/images/components/Menu/icon-aanbod.png") }
.text-menu.has-icon.is-active.icon-aanbod { background-image: url("https://www.dashboarddeelmobiliteit.nl/images/components/Menu/icon-aanbod-active.png") }
.text-menu.has-icon.icon-verhuringen { padding-left: 52px; background-image: url("https://www.dashboarddeelmobiliteit.nl/images/components/Menu/icon-verhuringen.png") }
.text-menu.has-icon.is-active.icon-verhuringen { background-image: url("https://www.dashboarddeelmobiliteit.nl/images/components/Menu/icon-verhuringen-active.png") }
.text-menu.has-icon.icon-ontwikkeling { padding-left: 52px; background-image: url("https://www.dashboarddeelmobiliteit.nl/images/components/Menu/icon-ontwikkeling.png") }
.text-menu.has-icon.is-active.icon-ontwikkeling { background-image: url("https://www.dashboarddeelmobiliteit.nl/images/components/Menu/icon-ontwikkeling-active.png") }
*/