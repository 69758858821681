.PillMenu,
.Overlay .PillMenu {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 70px;
  min-height: 45px;
  overflow-x: auto;
}
.PillMenu ul,
.PillMenu li,
.Overlay .PillMenu ul,
.Overlay .PillMenu li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.PillMenu li,
.Overlay .PillMenu li {
  font: normal normal normal 14px/45px Inter, sans-serif;
}
.PillMenu a,
.Overlay .PillMenu a {
  display: block;
  color: #343E47;
}
.PillMenu li.is-active a,
.PillMenu li a.active,
.Overlay .PillMenu li.is-active a,
.Overlay .PillMenu li a.active {
  color: #15AEEF;
}
.PillMenu a:after,
.Overlay .PillMenu a:after {
  content: "";
  padding-left: 15px;
  margin-right: 15px;
  border-right: 1px solid #343E47;
}
.PillMenu li:last-child a:after,
.Overlay .PillMenu li:last-child a:after {
  border-right: none;
}
