@media(min-width:  640px) {
  .ContentPage {
    padding-left: 324px;
  }
}
.ContentPage-inner {
}

/* Transition */
.ContentPage {
  transition: padding 0.4s;
  transition-timing-function: ease-out;
}
.ContentPage.full-page {
  transition: padding 0.6s;
  transition-timing-function: ease-in;
  padding-left: 0;
}

.ContentPage h1 {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
}

.ContentPage h2 {
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.ContentPage ul {
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
  margin-left: 1rem; /* 16px */
  list-style-type: disc;
}