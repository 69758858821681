@tailwind base;
@tailwind components;
@tailwind utilities;

.app {
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: Inter, sans-serif;
  position:absolute;
  width: 100vw;
  height: 100%;
  z-index: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.gui-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.map-layer {
  position: fixed;
  width: 100vw;
  z-index: 0;
  background-color: #fff;
  height: calc(100% - 60px);
}
@media(min-width: 640px) {
  .map-layer {
    height: 100%;
  }
}

.bg-theme-blue {
  background-color: #15AEEF
}

.agg-button-container {
  display: flex;
  flex-direction: row;
  user-select: none;
}

.agg-button {
  min-width: 40px;
  height: 25px;
  border-radius: 0.5em;
  text-align: center;
  font-size: 12px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 5px;
  margin: 0 5px;
}

.agg-button:hover,
.agg-button:focus,
.agg-button-active {
  cursor: pointer;
  color: white;
  background-color: #15AEEF;
}
.agg-button-active {
  cursor: initial;
}

.font-inter {
  font-family: Inter, sans-serif;
}

.pl-8 {
  padding-left: 2rem; /* 32px */
}
.relative {
  position: relative;
}
.left-2 {
  left: 0.5rem; /* 8px */
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}