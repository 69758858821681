.SelectLayerMobile {
  user-select: none;
  padding-bottom: 15px;
}

.SelectLayerMobile .layer {
  width: 80px;
  height: 80px;
  border: 1px solid #343E47;
  border-radius: 10px;
  opacity: 1;
  background: #fff center center no-repeat;
  background-size: cover;
  cursor: pointer;
  
  position: relative;
  display: inline-block;
  margin-top: 0.75rem; /* 12px */
  margin-bottom: 0.75rem; /* 12px */
  margin-left: 0.25rem; /* 4px */
  margin-right: 0.25rem; /* 4px */
  border: 4px solid #15AEEF;
}
.SelectLayerMobile .layer-inactive {
  border: 4px solid transparent;

}
.SelectLayerMobile .layer[data-type="vehicles"] {
  background-image: url('../../images/SelectLayer/layer-vehicles.png')
}
.SelectLayerMobile .layer[data-type="heat-map"] {
  background-image: url('../../images/SelectLayer/layer-heatmap.png')
}
.SelectLayerMobile .layer[data-type="od"] {
  background-image: url('../../images/SelectLayer/layer-od.png')
}
.SelectLayerMobile .layer[data-type="pointers"] {
  background-image: url('../../images/SelectLayer/layer-clusters.png')
}
.SelectLayerMobile .layer[data-type='zones'] {
  background-image: url('../../images/layer-zones.png')
}

.SelectLayerMobile .layer-title {
  display: block;
  position: absolute;
  bottom: -28px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  font: normal normal normal 12px/15px Inter;
  color: #15AEEF;
}
.SelectLayerMobile .layer-inactive .layer-title {
  color: black;
}
