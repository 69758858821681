.map {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-sizing: border-box;
  border-width: 2px;
  border-color: rgb(255 255 255);
  background-color: rgb(59 130 246);
}

.Map-popup-title {
  font: normal normal 600 20px/24px Inter, sans-serif;
  color: #0B3D20;
}

.Map-popup-body {
  font: normal normal normal 14px/20px Inter, sans-serif;
  color: #333333;
}

.maplibregl-popup {
  max-width: 306px !important;
}

.mapboxgl-popup-content,
.maplibregl-popup-content {
  box-shadow: none;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  padding: 16px;
}

/* Hide close button */
/* Clicking somewhere else on the map has closed the popup as well */
.mapboxgl-popup-close-button,
.maplibregl-popup-close-button {
  margin-right: 5px;
  margin-top: 5px;
  font-size: 20px;
  display: none;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
  position: relative;
  top: -1px;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.maplibregl-popup-anchor-right .maplibregl-popup-tip {
  position: relative;
  left: -1px;
}

/* CONTROLS */

/* Controls wrapper */
.mapboxgl-ctrl-group:not(:empty),
.maplibregl-ctrl-group:not(:empty) {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.mapboxgl-ctrl-group button, .maplibregl-ctrl-group button {
  width: 43px;
  height: 43px;
}

/* Zoom in */
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
.maplibregl-ctrl button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon {
  background-image: url('./images/controls-zoom-in.svg');
  background-size: 16px;
}

/* Zoom out */
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon,
.maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
  background-image: url('./images/controls-zoom-out.svg');
  background-size: 16px;
}
