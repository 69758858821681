.loadingindicator {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 266px;
  top: 19px;
  z-index: 999;

  -webkit-animation: spinner 1500ms linear infinite;
  animation: spinner 1500ms linear infinite;
}

@-webkit-keyframes spinner{
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spinner{
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

