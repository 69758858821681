.filter-herkomst-bestemming-container {
  width: 100%;
  height: auto;
  
  background-color: transparent;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0px;
  color: #000000;
}

.filter-herkomst-bestemming-box-row {
  padding: 0 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: auto;

  flex-grow: 0 1;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  opacity: 1;
}

.filter-herkomst-bestemming-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  vertical-align:bottom;
  height: 100%;
  min-height: 40px;
  width: 50%;
  color: #C3C3C3;
}

.filter-herkomst-bestemming-button-active {
  color: #FFFFFF;
  background-color: #15AEEF;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  opacity: 1;
}
