.filter-plaats-container {
  width: 100%;
  
  background-color: transparent;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0px;
  color: #000000;
}

.filter-plaats-title {
  text-align: left;
  opacity: 1;
  background-color: transparent;
  width: auto;
  margin-bottom: 10px;
}

.filter-plaats-value {
  width: 100%;
  color: #15AEEF;
  text-align: left;
  padding-left: 12px;
  height: 100%;
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.filter-plaats-value.text-black {
  color: #000000;
}

.filter-plaats-not-active {
  opacity: 0.6;
  filter: grayscale(100%);
}


.filter-plaats-box-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  height: 46px;

  flex-grow: 0 1;
  justify-content: flex-start;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  opacity: 1;
}

.filter-plaats-img-search {
  width: 18px;
  height: 18px;
  background: #FFFFFF url('../../../images/search-solid.svg') no-repeat center border-box;
  margin-right: 12px;
}

.filter-plaats-img-cancel {
  width: 18px;
  height: 18px;
  background: #FFFFFF url('../../../images/times-circle.svg') no-repeat center border-box;
  margin-right: 12px;
}
