.Overlay {
  z-index: 20;
}
.Overlay h1 {
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.Overlay h2 {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
  font-weight: bold;
}

.Overlay h3 {
  font-weight: bold;
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.Overlay ul {
  list-style-type: disc;
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
  margin-left: 1rem; /* 16px */
}

.Overlay ol {
  list-style-type: decimal;
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
  margin-left: 1rem; /* 16px */
}

.Overlay p {
  margin-bottom: 1rem; /* 16px */
}

.Overlay a {
  text-decoration: none;
  color: #009fe3;
}
.Overlay a:hover {
  text-decoration: underline;
}

.Overlay pre {
  overflow-x:auto;
}
