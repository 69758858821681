
.Button-orange {
  background-color: #FD862E;
  color: #fff;
  border-color: transparent;
}

.Button-blue {
  background-color: #15AEEF;
  color: #fff;
  border-color: transparent;
}