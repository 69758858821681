.SlideBox {
  transition: transform 0.4s, max-width 1s;
  transition-timing-function: ease-out;
}
.SlideBox.is-hidden {
  transition: transform 0.6s;
  transition-timing-function: ease-in;
}
.SlideBox.direction-right.is-hidden {
  transform: translateX(99%);
}
.SlideBox.direction-left.is-hidden {
  transform: translateX(-99%);
}

.SlideBox-inner {
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  overflow-y: auto;
  overflow-x: visible;
  position: relative;
  z-index: 10;
}
.SlideBox.direction-right .SlideBox-inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 3;
  position: relative;
}
.SlideBox.direction-left .SlideBox-inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.SlideBox-toggle-wrapper {
  z-index: 9;
  position: absolute;
  margin-left: 4px;
  top: 0;

  width: 28px;
  opacity: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.SlideBox.direction-right .SlideBox-toggle-wrapper {
  left: -32px;
}
.SlideBox.direction-left .SlideBox-toggle-wrapper {
  right: -22px;
  z-index: 9;
}
.SlideBox-toggle {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: -2px 1px 3px #00000029;
  border-radius: 10px;
}
.SlideBox.direction-right .SlideBox-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  cursor: e-resize;
}
.SlideBox.direction-left .SlideBox-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: w-resize;
}
.SlideBox.is-hidden.direction-left .SlideBox-toggle {
  cursor: e-resize;
}
.SlideBox.is-hidden.direction-right .SlideBox-toggle {
  cursor: w-resize;
}
.SlideBox-toggle-wrapper span {
  transform-origin: top center;
  left: 0;
  top: 0;
  transform: rotate(-90deg) translateX(-200%) translateY(-12px);
  display: block;
  height: 100px;
  font: normal normal normal 14px/28px Inter, sans-serif;
}
.SlideBox.direction-left .SlideBox-toggle-wrapper span {
  transform-origin: top right;
  right: 0;
  transform: rotate(90deg) translateX(200%);
}
.SlideBox.direction-left .SlideBox-toggle {
  box-shadow: 0px 3px 6px #00000029;
}
