.FilterbarExtended {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  overflow-y: auto;
  z-index: 10;
  background: #F6F5F4;
  width: 100%;
  overflow-x: hidden;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  padding-bottom: 2rem; /* 32px */
}
.FilterbarExtended h1.title {
  padding: 15px 0;
  font-size: 16px;
}

.FilterbarExtended-close-button-wrapper {
  padding: 15px 5px;
}

@media (min-width: 640px) {
  .FilterbarExtended {
    position: absolute;
    left: 320px;
    width: 300px;
    padding-left: 0.25rem; /* 4px */
    padding-right: 0.25rem; /* 4px */
  }  
  .FilterbarExtended-close-button-wrapper {
    padding: 5px 5px;
  }
}
